import s from "./Main.module.scss";
import { useEffect, useState } from "react";
const access_token = process.env.REACT_APP_ACCESS_TOKEN;

const Main = () => {
	const [tableHeaderCellList] = useState([
		//, setTableHeaderCellList
		{ name: "Первичный контакт", dealsNum: 0, dealsSum: 0 },
		{ name: "Переговоры", dealsNum: 0, dealsSum: 0 },
		{ name: "Принимают решение", dealsNum: 0, dealsSum: 0 },
		{ name: "Согласование договора", dealsNum: 0, dealsSum: 0 },
	]);
	const [tableBodyCellList] = useState([
		//, setTableBodyCellList
		// { name: "Первичный контакт", dealsNum: 0, dealsSum: 0 },
		// { name: "Переговоры", dealsNum: 0, dealsSum: 0 },
		// { name: "Принимают решение", dealsNum: 0, dealsSum: 0 },
		// { name: "Согласование договора", dealsNum: 0, dealsSum: 0 },
	]);

	const getDealsText = (num: number) => {
		return "сделок";
	};

	const auth = async (access_token: string) => {
		try {
			let xhr = new XMLHttpRequest();
			xhr.open("GET", "https://ilyanovyy.amocrm.ru/api/v4/leads");
			xhr.setRequestHeader("Authorization", `Bearer ${access_token}`);
			xhr.send();
			xhr.onload = function () {
				let responseObj = xhr.response;
				console.log(responseObj);
			};
			xhr.onerror = function (err) {
				console.log(err);
			};
			// const resp = await fetch("https://ilyanovyy.amocrm.ru/api/v4/leads", {
			// 	headers: { Authorization: `Bearer ${access_token}` },
			// });
			// console.log(resp);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		if (access_token) {
			auth(access_token);
		}
	}, [access_token]);

	return (
		<main className={s.main}>
			<div className={s.table}>
				<ul className={s.tableColumnList}>
					{tableHeaderCellList.map((cell) => (
						<li className={s.tableCell}>
							<h3>{cell.name}</h3>
							<p>
								{cell.dealsNum} {getDealsText(cell.dealsNum)}: {cell.dealsSum} ₽
							</p>
						</li>
					))}
				</ul>
			</div>
		</main>
	);
};

export default Main;
