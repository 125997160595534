import "src/styles/normalize.scss";
import "src/styles/index.scss";
import React from "react";
import Main from "./pages/Main/Main";
import ReactDOM from "react-dom/client";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<Main />
	</React.StrictMode>
);
